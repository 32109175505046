import React from 'react'
import style from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/layout'
import PostCard from '../components/postcard'

const Info = style.h1`
  padding: 1em;
  color: dimgray;
`

const Container = style.div`
  padding: 1em 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 15em);
  grid-auto-rows: repeat(auto-fit, 1fr);
  grid-column-gap: 1.5em;
  grid-row-gap: 2em;
  place-content: center;
`

const Blog = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                date(formatString: "DD/MM/YYYY")
                description
                heroImage {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              excerpt
            }
          }
        }
      }
    `
  )
  return (
    <Layout colorful={true}>
      <SEO title="Blog" />
      {data.allMarkdownRemark.edges.length ? (
        <Container>
          {data.allMarkdownRemark.edges.map(({ node: post }) => (
            <PostCard
              title={post.frontmatter.title}
              link={post.fields.slug}
              date={post.frontmatter.date}
              excerpt={
                post.frontmatter.description
                  ? post.frontmatter.description
                  : post.excerpt
              }
              image={post.frontmatter.heroImage}
            />
          ))}
        </Container>
      ) : (
        <Info>Ehi! Purtroppo non ci sono ancora post nel nostro blog. 🤷‍</Info>
      )}
    </Layout>
  )
}

export default Blog
