import React from 'react'
import style from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import tw from 'tailwind.macro'

const Card = style(Link)`
    ${tw`items-center w-64 h-auto text-black no-underline rounded-lg bg-grey-lightest`};

    filter: drop-shadow(.2em .2em 0.4rem rgb(212, 212, 212));
    &:hover {
        transform: translateY(-0.2em);
        filter: drop-shadow(.2em .5em 0.7rem rgb(212, 212, 212));
        transition: ease-in-out .3s;
    }
`

const Content = style.div`
  ${tw`p-2`}
  display: grid;
  grid-gap: .8em;
  grid-template:
    "heroimage" 8em
    "title" 1em
    "date" 1em
    "excerpt" 1fr
    "footer" 1em
    / auto;
`

// const Image = style(Img)``
const Image = style(Img)`
    grid-area: heroimage;
    ${tw`rounded-lg`}
`

const Title = style.div`
    grid-area: title;
    ${tw`font-serif text-lg font-bold`}
`

const Date = style.div`
    grid-area: date;
    ${tw`font-sans text-xs font-light text-grey-darker`}
`

const Excerpt = style.div`
    grid-area: excerpt;
    ${tw`font-sans text-base font-light`}
`

const Footer = style.div`
    grid-area: footer;
    ${tw`font-sans text-sm italic font-medium text-center text-grey-darkest`}
`

const PostCard = ({ title, link, date, excerpt, image }) => (
  <Card to={link} image={image}>
    <Content>
      {image ? <Image fluid={image.childImageSharp.fluid} /> : <></>}
      <Title>{title}</Title>
      <Date>{date}</Date>
      <Excerpt>{excerpt}</Excerpt>
      <Footer>Leggi di più...</Footer>
    </Content>
  </Card>
)

export default PostCard
